import React, { createContext, useContext, useState, useEffect } from 'react'
import { NOTIFICATION_IDS } from 'components/ui/utils/constant'
import { Notification, toast } from 'components/ui'
import { useDispatch, useSelector } from 'react-redux'
import { supabase } from 'services/Supabase'
import pusher from 'services/pusher'
import store from 'store'
import {
    authenticateUser,
    getPaymentSetting,
    initialState,
    updatePaymentSettingLocally,
} from 'store/auth/userSlice'
import useSupaAuth from 'utils/hooks/useSupaAuth'
import { apiPaymentSettings } from 'services/AuthService'
import { isArray } from 'lodash'
import { onSignOutSuccess } from 'store/auth/sessionSlice'

const SupabaseContext = createContext()

export const useSupabase = () => useContext(SupabaseContext)

export const SupabaseProvider = ({ children }) => {
    const authData = useSelector((state) => state.auth)
    const userSelector = authData?.user
    const { token, signedIn } = authData?.session
    const [session, setSession] = useState(supabase.auth.getSession())
    const { authenticated } = useSupaAuth()
    const dispatch = useDispatch()

    useEffect(() => {
        if (
            token &&
            signedIn &&
            (userSelector?.authority === null ||
                userSelector?.authority === undefined ||
                !isArray(userSelector?.authority)) &&
            isArray(userSelector?.authority) &&
            userSelector?.authority.length === 0
        ) {
            dispatch(onSignOutSuccess())
            dispatch(authenticateUser(initialState))
            localStorage.clear()
        }
    }, [])

    useEffect(() => {
        const init = async () => {
            try {
                const { data: sessionData, error } =
                    await supabase.auth.getSession()
                if (error) {
                    throw error
                }
                sessionUpdated(sessionData.session)
            } catch (error) {
                console.error('Error retrieving session:', error)
            }
        }

        init()

        if (authenticated && userSelector?.auth_id) {
            dispatch(getPaymentSetting())
        }

        const { data: authListener } = supabase.auth.onAuthStateChange(
            async (event, sessionData) => {
                if (sessionData?.session) {
                    sessionUpdated(sessionData.session)
                }
            }
        )

        return () => {
            authListener.subscription.unsubscribe()
            unSubscribeChannels()
        }
    }, [])

    useEffect(() => {
        pusherUpdate()
    }, [])

    const sessionUpdated = async (sessionData) => {
        setSession(sessionData)
        if (sessionData) {
            // try {
            //     const userData = await getUserAndProfileByAuthId(
            //         sessionData.user.id
            //     )
            //     dispatch(onSignInSuccess(sessionData.access_token))
            //     console.log('onSignInSuccess')
            //     dispatch(
            //         authenticateUser({
            //             auth_id: sessionData.user.id,
            //             ...userData,
            //             profile: userData?.vendors,
            //         })
            //     )
            // } catch (error) {
            //     console.error('Error fetching user profile:', error)
            // }
        } else {
            setSession(null)
        }
    }

    const unSubscribeChannels = () => {
        if (pusher && userSelector?.auth_id) {
            pusher.unsubscribe(`stripe-${userSelector?.auth_id}`)
        }
    }

    const pusherUpdate = async () => {
        console.log('in pusher udpate')
        if (!userSelector || !userSelector.auth_id) {
            return
        }
        if (!authenticated || authenticated === null) {
            return
        }

        //need to discuss
        // const subscribedChannels = Object.keys(pusher?.channels?.channels)

        if (userSelector && userSelector?.auth_id) {
            const StripeChannelName = `stripe-${userSelector?.auth_id}`
            const stripeChannel = pusher.subscribe(StripeChannelName)
            stripeChannel.bind('message', async (obj) => {
                if (
                    obj &&
                    obj.key === NOTIFICATION_IDS.STRIPE_SUBSCRIPTION_STATUS
                ) {
                    const { data } = await apiPaymentSettings()
                    const { user } = store.getState()?.auth
                    if (data?.data?.uuid && user) {
                        dispatch(updatePaymentSettingLocally(data?.data))
                    }
                    if (obj.status === 'error') {
                        toast.push(
                            <Notification
                                title={
                                    data?.data?.errorMessage ||
                                    'Payment Failed!'
                                }
                                type="danger"
                            />,
                            {
                                placement: 'top-center',
                            }
                        )
                    } else if (obj.status === 'success') {
                        // toast.success(obj.message.message)
                        toast.push(
                            <Notification title={obj.message} type="success" />,
                            {
                                placement: 'top-center',
                            }
                        )
                    }
                }
            })
        }
    }

    return (
        <SupabaseContext.Provider
            value={{
                unSubscribeChannels,
                supabase,
                session,
            }}
        >
            {children}
        </SupabaseContext.Provider>
    )
}
