import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getUserProfileByUUID } from 'services/Supabase/SupabaseServices'

const initialState = {
    allVendors: {
        tableData: {
            pageIndex: 1,
            pageSize: 10,
            sort: '',
            query: '',
            total: 0,
        },

        loading: false,
    },
    products: [],
    services: [],
    customers: [],
    selectedVendor: null,
    selectedVendorLoader: false,
    selectedVendorError: '',
}

export const fetchUserAndVendorProfile = createAsyncThunk(
    'get/user-vendor-profile',
    async (auth_id) => {
        try {
            const userData = await getUserProfileByUUID(auth_id)
            return { userData: userData || null }
        } catch (error) {
            throw error
        }
    }
)

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        setAllVendorsLocally: (state, action) => {
            state.allVendors = action.payload
            state.loading = false
        },
        setAllProductsLoclly: (state, action) => {
            state.products = action.payload
            state.loading = false
        },
        setAllServicesLoclly: (state, action) => {
            state.services = action.payload
            state.loading = false
        },
        setAllCustomersLoclly: (state, action) => {
            state.customers = action.payload
            state.loading = false
        },
        resetSelectedVendorStateLocally: (state) => {
            state.selectedVendor = null
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserAndVendorProfile.pending, (state) => {
                state.selectedVendorLoader = true
            })
            .addCase(
                fetchUserAndVendorProfile.fulfilled,
                (state, { payload }) => {
                    state.selectedVendor = {
                        ...payload.userData.profile,
                        username: payload.userData.username,
                        email: payload.userData.email,
                    }
                    state.selectedVendorLoader = false
                }
            )
            .addCase(fetchUserAndVendorProfile.rejected, (state, action) => {
                state.selectedVendorLoader = false
                state.selectedVendorError =
                    action.error.message ??
                    'An error occurred in Payment setting'
            })
    },
})

export const {
    setAllVendorsLocally,
    setAllProductsLoclly,
    setAllServicesLoclly,
    setAllCustomersLoclly,
    resetSelectedVendorStateLocally,
} = adminSlice.actions

export const selectedVendorSelector = (state) => state.admin.selectedVendor
export const selectedVendorLoaderSelector = (state) =>
    state.admin.selectedVendorLoader || false

export default adminSlice.reducer
