import { combineReducers } from '@reduxjs/toolkit'
import session from './sessionSlice'
import user from './userSlice'
import feedback from './feedbackSlice'
import generation from './generationSlice'

const reducer = combineReducers({
    session,
    user,
    feedback,
    generation
})

export default reducer
