import { parseBooleanVal } from './utils'

export const STRIPE_CHECK_SUBSCRIPTION_STATUS = {
    SUBSCRIBED: 'subscribed',
    PAYMENTFAILED: 'paymentFailed',
    RETRY_PAYMENT_FAILED: 'retryPaymentFailed',
    SUBSCRIPTION_UNPAID: 'subscription_unpaid',
    FREEMIUM: 'freemium',
}

export const checkSubscriptionStatus = (payment_setting) => {
    if (!payment_setting) return null

    if (
        payment_setting?.current_subscription_status === 'active' &&
        payment_setting?.subscription_status === true &&
        payment_setting.hasOwnProperty('subscription_id') &&
        payment_setting.subscription_id !== null
    ) {
        return STRIPE_CHECK_SUBSCRIPTION_STATUS.SUBSCRIBED
    } else if (
        payment_setting?.subscription_status === false &&
        payment_setting.hasOwnProperty('subscription_id') &&
        payment_setting.subscription_id !== null &&
        (payment_setting?.current_subscription_status === 'incomplete' ||
            payment_setting.current_subscription_status ===
                'incomplete_expired')
    ) {
        return STRIPE_CHECK_SUBSCRIPTION_STATUS.PAYMENTFAILED
    } else if (
        parseBooleanVal(payment_setting.subscription_status) === false &&
        payment_setting.hasOwnProperty('subscription_id') &&
        payment_setting.subscription_id !== null &&
        payment_setting.current_subscription_status !== null &&
        payment_setting.current_subscription_status === 'unpaid' &&
        payment_setting.attempt_count === null
    ) {
        return STRIPE_CHECK_SUBSCRIPTION_STATUS.SUBSCRIPTION_UNPAID
    } else if (
        parseBooleanVal(payment_setting.subscription_status) === false &&
        payment_setting.hasOwnProperty('subscription_id') &&
        payment_setting.subscription_id !== null &&
        payment_setting.current_subscription_status !== null &&
        payment_setting.current_subscription_status === 'past_due' &&
        payment_setting.attempt_count !== null &&
        payment_setting.attempt_count > 0
    ) {
        return STRIPE_CHECK_SUBSCRIPTION_STATUS.RETRY_PAYMENT_FAILED
    }
    return STRIPE_CHECK_SUBSCRIPTION_STATUS.FREEMIUM
}
