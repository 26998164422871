import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    feedbacks: [],
}
export const feedbackSlice = createSlice({
    name: 'feedback',
    initialState,
    reducers: {
        setFeedbacksLocally: (state, action) => {
            // console.log('action', action)
            state.feedbacks = action.payload
        },
    },
})

export const { setFeedbacksLocally } = feedbackSlice.actions

export default feedbackSlice.reducer
