import ApiService from './ApiService'

export async function apiSignIn(data) {
    return ApiService.fetchData({
        url: '/sign-in',
        method: 'post',
        data,
    })
}

export async function apiSignUp(data) {
    return ApiService.fetchData({
        url: '/sign-up',
        method: 'post',
        data,
    })
}

export async function apiSignOut(data) {
    return ApiService.fetchData({
        url: '/sign-out',
        method: 'post',
        data,
    })
}

export async function apiForgotPassword(data) {
    return ApiService.fetchData({
        url: '/forgot-password',
        method: 'post',
        data,
    })
}

export async function apiResetPassword(data) {
    return ApiService.fetchData({
        url: '/reset-password',
        method: 'post',
        data,
    })
}

export async function apiCreateStripeCustomer(data) {
    return ApiService.fetchData({
        url: '/stripe/create-customer',
        method: 'post',
        data,
    })
}

export async function apiStripeCustomerPortal() {
    return ApiService.fetchData({
        url: '/stripe/customer-portal-session',
        method: 'get',
        data: {},
    })
}

export async function apiStripeGetPaymentMethods() {
    return ApiService.fetchData({
        url: '/stripe/payment-methods',
        method: 'get',
        data: {},
    })
}

export async function apiStripeBuySubscription(data) {
    return ApiService.fetchData({
        url: '/stripe/buy-subscription',
        method: 'post',
        data,
    })
}

export async function apiPaymentSettings() {
    return ApiService.fetchData({
        url: '/stripe/payment-settings',
        method: 'get',
    })
}

export async function apiRetryPayment() {
    return ApiService.fetchData({
        url: '/stripe/retry-payment',
        method: 'get',
    })
}

export async function apiGetUpcomingInvoice() {
    return ApiService.fetchData({
        url: '/stripe/upcoming-invoice',
        method: 'get',
    })
}

export async function apiFetchStripeInvoices(payload) {
    let url = '/stripe/invoices'
    if (payload?.status) {
        url = `${url}?status=${payload.status}`
    }
    return ApiService.fetchData({
        url,
        method: 'get',
    })
}

export async function apiGetAdminStripeInvoices(payload) {
    let url = '/stripe/admin/invoices'
    if (payload?.pageIndex) {
        url = `${url}?pageIndex=${payload.pageIndex}`
    }
    if (payload?.pageSize) {
        url = `${url}&pageSize=${payload.pageSize}`
    }
    if (payload?.status) {
        url = `${url}&status=${payload.status}`
    }
    return ApiService.fetchData({
        url,
        method: 'get',
    })
}

export async function apiGetAdminSubscriptionsStatus() {
    let url = '/stripe/admin/subscription-stats'
    return ApiService.fetchData({
        url,
        method: 'get',
    })
}
