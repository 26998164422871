const appConfig = {
    // apiPrefix: 'http://192.168.1.19:5000',
    // apiPrefix: '/api',
    apiPrefix: 'https://be.maiproperty.ai',
    // apiPrefix: 'http://localhost:5000',
    authenticatedEntryPath: '/vendor/settings/profile',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}

export default appConfig
