import { useSelector, useDispatch } from 'react-redux'
import {
    authenticateUser,
    getPaymentSetting,
    initialState,
    userLoggedOut,
} from 'store/auth/userSlice'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import {
    checkVendorIsBlocked,
    createUserProfile,
    getUserAndProfileByAuthId,
    signOutFromSupabase,
    signUpWithEmailAndPassword,
} from 'services/Supabase/SupabaseServices'
import { supabase } from 'services/Supabase'
import { ADMIN, VENDOR } from 'constants/roles.constant'
import { setFeedbacksLocally } from 'store/auth/feedbackSlice'

function useSupaAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth?.session)

    // const handleAuthState = async (user) => {
    //     try {
    //         const { data: userData, error: userError } = await supabase
    //             .from('users')
    //             .select('*, vendors (*)')
    //             .eq('auth_id', user.id)
    //             .single()
    //         if (userError) {
    //             console.error('Error fetching user data:', userError.message)
    //             // return
    //         }

    //         return { ...userData, profile: userData?.vendors }
    //     } catch (Err) {
    //         console.log('err', Err)
    //         return null
    //     }
    // }

    const signIn = async (values) => {
        const { email: identifier, password } = values;
        try {
            const { data: userByIdentifier, error: userByIdentifierError } = await supabase
                .from("users")
                .select("email, username, customers(*)")
                .or(`email.eq.${identifier},username.eq.${identifier}`)
                .single();
    
            if (userByIdentifierError) {
                throw new Error(userByIdentifierError?.message);
            }
    
            if (!userByIdentifier || !userByIdentifier.email) {
                throw new Error('User not found with provided email or username.');
            }
    
            const { data, error } = await supabase.auth.signInWithPassword({
                email: userByIdentifier.email,
                password,
            });
    
            if (error) {
                throw new Error(error?.message);
            }
    
            if (data) {
                const { access_token } = data.session;
                const dataObj = await getUserAndProfileByAuthId(data.user.id);
    
                if (dataObj.role === VENDOR) {
                    await checkVendorIsBlocked(dataObj?.uuid);
                }
    
                if (dataObj) {
                    dispatch(onSignInSuccess(access_token));
                    dispatch(authenticateUser({
                        auth_id: data.user.id,
                        ...dataObj,
                        authority: [dataObj.role],
                    }));
                    dispatch(getPaymentSetting(data.user.id));
                } else {
                    throw Error('User not found in the profile lookup!');
                }
    
                const redirectUrl = query.get(REDIRECT_URL_KEY);
    
                navigate(
                    redirectUrl
                        ? redirectUrl
                        : `${dataObj.role === ADMIN ? '/admin' : '/vendor'}/dashboard`
                );
    
                return {
                    status: 'success',
                    message: '',
                };
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.message || 'Something went wrong!',
            };
        }
    };

    const handleRedirectionAfterSignup = (data, access_token, userData) => {
        dispatch(onSignInSuccess(access_token))
        dispatch(getPaymentSetting(data.user.id))
        if (data.user) {
            dispatch(
                authenticateUser({
                    auth_id: data.user.id,
                    authority: [VENDOR],
                    email: data.user.email,
                    uuid: userData.uuid,
                    id: userData.id,
                    username: userData.username,
                    profile: userData?.profile || null,
                })
            )
            navigate(appConfig.unAuthenticatedEntryPath)
            return {
                status: 'success',
                message: '',
            }
        }
    }

    const signUp = async (values) => {
        // console.log('values', values)
        // return;
        const { email, password, username } = values

        try {
            const { data, error } = await signUpWithEmailAndPassword(
                email,
                password
            )

            if (error) {
                throw error
            }

            if (data?.user) {
                await createUserProfile({
                    ...data.user,
                    username: username?.toLowerCase(),
                })
                const { access_token } = data.session

                const userData = await getUserAndProfileByAuthId(data.user.id)
                    .then((x) => x)
                    .catch(() => null)

                fetch(`${appConfig.apiPrefix}/stripe/create-customer`, {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${access_token}`,
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                })
                    .then((response) => response.json())
                    .then(() =>
                        handleRedirectionAfterSignup(
                            data,
                            access_token,
                            userData
                        )
                    )
                    .catch(() =>
                        handleRedirectionAfterSignup(
                            data,
                            access_token,
                            userData
                        )
                    )
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.message || 'Something went wrong.',
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(authenticateUser(initialState))
        dispatch(userLoggedOut())
        dispatch(setFeedbacksLocally([]))
        localStorage.clear()
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await signOutFromSupabase()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useSupaAuth
