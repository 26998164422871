import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    allGenerations: [],
}
export const generationSlice = createSlice({
    name: 'generation',
    initialState,
    reducers: {
        setGenerationsLocally: (state, action) => {
            // console.log('action', action)
            state.allGenerations = action.payload
        },
    },
})

export const { setGenerationsLocally } = generationSlice.actions

export default generationSlice.reducer