const parseError = (error) => {
    let message = ''
    // console.log('error', error)
    if (error.response) {
        message = error.response.data.error ?? error.response.data.message
    } else if (error.request) {
        message = 'Network Error!'
    } else {
        message = error.message
    }
    throw new Error(message)
}

export default parseError
