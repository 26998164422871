import { useMediaQuery } from 'react-responsive';

const RecordNotFound = () => {
    const isMdUp = useMediaQuery({ query: '(max-width: 768px)' });

    return (
        <main className="flex flex-grow items-center py-20 justify-center">
            <div className="container mx-auto px-4 lg:px-8 text-center">
                <div className="flex justify-center mb-4">
                    <img
                        src="/img/folder.svg"
                        alt="Not found"
                        className="h-14 w-auto opacity-30"
                    /> 
                 
                </div>
                <h1 className={`opacity-60 ${isMdUp ? 'text-xl' : 'text-4xl'}`}>
                    No Record Found
                </h1>
                {/* <p className="text-secondary mt-1">
                    {t(tokens.common.empty_state.subheading)}
                </p> */}
            </div>
        </main>
    );
}

export default RecordNotFound;