import React, { useState, useEffect } from 'react'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'
import { imagePathSupabase, urlToFile } from 'utils/utils'

// const BYTE = 1000
// const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({ children }) => {
    return <span className="text-4xl">{children}</span>
}

const FileItemDilogue = (props) => {
    const { file, isCallBackOfImgClick, isCoverImg, fileName } = props

    const [thumbnail, setThumbnail] = useState(null)

    useEffect(() => {
        const loadThumbnail = async () => {
            let fileObj = null

            if (file instanceof File) {
                fileObj = file
            } else {
                fileObj = await urlToFile(
                    imagePathSupabase(file),
                    'image',
                    'image/jpeg'
                )
            }

            const { type, name } = fileObj
            const isImageFile = type.split('/')[0] === 'image'

            if (isImageFile) {
                setThumbnail(
                    <img
                        onClick={() => {
                            if (isCallBackOfImgClick) {
                                isCallBackOfImgClick(fileName)
                            }
                        }}
                        className={`upload-file-image rounded-md ${
                            isCallBackOfImgClick && 'cursor-pointer'
                        }`}
                        src={
                            file instanceof File
                                ? URL.createObjectURL(fileObj)
                                : imagePathSupabase(file)
                        }
                        alt={`file preview ${name}`}
                        style={{
                            height: '100%',
                            overflow: 'hidden',
                            width: '100%',
                        }}
                    />
                )
            } else if (type === 'application/zip') {
                setThumbnail(
                    <FileIcon>
                        <VscFileZip />
                    </FileIcon>
                )
            } else if (type === 'application/pdf') {
                setThumbnail(
                    <FileIcon>
                        <VscFilePdf />
                    </FileIcon>
                )
            } else {
                setThumbnail(
                    <FileIcon>
                        <VscFile />
                    </FileIcon>
                )
            }
        }

        loadThumbnail()
    }, [file])

    return (
        <div className="upload-file border-none px-1">
            <div
                className="relative upload-file-thumbnail object-cover p-0 border-none"
                style={{ width: '74px', height: '42px' }}
            >
                {isCoverImg === fileName && (
                    <span className="absolute flex justify-center items-center left-[30px] top-[10px] w-[19px] h-[19px] rounded-full bg-white text-black z-10">
                       <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        viewBox="0 0 20 20"
                        fill="none"
                    >
                        <path
                            d="M2.5 10.75L8.5 16.75L17.5 3.25"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        />
                    </svg>
                    </span>
                )}
                {thumbnail}
                <span
                    onClick={() => props.removeFile(props.index)}
                    className="cursor-pointer text-md absolute flex justify-center items-center right-0 top-[-16px]  z-10"
                >
                    x
                </span>
            </div>
        </div>
    )
}

export default FileItemDilogue
