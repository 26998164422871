import { combineReducers } from 'redux'
import theme from './theme/themeSlice'
import auth from './auth'
import base from './base'
import locale from './locale/localeSlice'
import admin from './admin/adminSlice'
const rootReducer = (asyncReducers) => (state, action) => {
    let prevState = state
    // console.log('action', action)
    if (action.type === 'auth/session/onSignOutSuccess') prevState = undefined

    const combinedReducer = combineReducers({
        theme,
        auth,
        base,
        locale,
        admin,
        ...asyncReducers,
    })
    return combinedReducer(prevState, action)
}

export default rootReducer
