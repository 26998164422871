import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiPaymentSettings } from 'services/AuthService'
import { getUserAndProfileByAuthId } from 'services/Supabase/SupabaseServices'
import {
    STRIPE_CHECK_SUBSCRIPTION_STATUS,
    checkSubscriptionStatus,
} from 'utils/billingSubscription'

export const initialState = {
    auth_id: '',
    uuid: '',
    avatar: '',
    username: '',
    email: '',
    authority: [],
    profile: null,
    vendors: null,
    paymentSettings: null,
}

export const getPaymentSetting = createAsyncThunk(
    'get/payment-settings',
    async () => {
        try {
            const { data } = await apiPaymentSettings()
            return { paymentSettings: data?.data || null }
        } catch (error) {
            throw error
        }
    }
)

export const fetchUserProfile = createAsyncThunk(
    'get/user-profile',
    async (auth_id) => {
        try {
            const userData = await getUserAndProfileByAuthId(auth_id)
            // console.log('userData', userData)
            return { userData: userData || null }
        } catch (error) {
            throw error
        }
    }
)

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        authenticateUser: (state, action) =>
            (state = { ...state, ...action.payload }),
        updateProfileLocally: (state, action) => {
            state.profile = { ...state.profile, ...action.payload }
            state.username = action.payload.hasOwnProperty('username')
                ? action.payload.username
                : state.username
        },
        userLoggedOut: () => initialState,
        updatePaymentSettingLocally: (state, action) => {
            state.paymentSettings = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchUserProfile.pending, (state) => {
                state.userDataFetchLoader = true
            })
            .addCase(fetchUserProfile.fulfilled, (state, { payload }) => {
                state.profile = payload.userData?.profile
                    ? {
                          ...payload.userData.profile,
                          username: payload.userData.username,
                          email: payload.userData.email,
                      }
                    : null
                state.username = payload.userData.username
                state.email = payload.userData.email
                state.userDataFetchLoader = false
            })
            .addCase(fetchUserProfile.rejected, (state, action) => {
                state.userDataFetchLoader = false
                state.userDataFetchError =
                    action.error.message ??
                    'An error occurred in Payment setting'
            })

        builder
            .addCase(getPaymentSetting.pending, (state) => {
                state.paymentSettingFetchLoader = true
            })
            .addCase(getPaymentSetting.fulfilled, (state, { payload }) => {
                state.paymentSettings = payload.paymentSettings
                state.paymentSettingFetchLoader = false
            })
            .addCase(getPaymentSetting.rejected, (state, action) => {
                state.paymentSettingFetchLoader = false
                state.paymentSettingFetchError =
                    action.error.message ??
                    'An error occurred in Fetching User Data.'
            })
    },
})

export const getUserProfileSelector = (state) => state.auth.user.profile || null
export const getUserProfileFetchLoaderSelector = (state) =>
    state.auth.user.userDataFetchLoader || false

export const isUserPaidPlanAndValidSelector = (state) =>
    checkSubscriptionStatus(state.auth.user.paymentSettings) === null
        ? false
        : checkSubscriptionStatus(state.auth.user.paymentSettings) ===
          STRIPE_CHECK_SUBSCRIPTION_STATUS.SUBSCRIBED

export const {
    authenticateUser,
    updateProfileLocally,
    userLoggedOut,
    updatePaymentSettingLocally,
} = userSlice.actions

export default userSlice.reducer
