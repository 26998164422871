import supabaseConfig from 'configs/supabase.config'
import moment from 'moment'
import dayjs from 'dayjs'

export const BUCKET_NAME = 'services'

export const imagePathSupabase = (path) => {
    if (!path) return null
    return `${supabaseConfig.authDomain}/storage/v1/object/public/${BUCKET_NAME}/${path}`
}
export const logoPathSupabase = (path) => {
    if (!path) return null
    return `${supabaseConfig.authDomain}/storage/v1/object/logo/${BUCKET_NAME}/${path}`
}

export async function urlToFile(imageUrl, filename, mimeType = 'image/jpg') {
    try {
        const response = await fetch(imageUrl)

        if (!response.ok) {
            throw new Error('Network response was not ok.')
        }

        const blob = await response.blob()

        const file = new File([blob], filename, { type: mimeType })

        return file
    } catch (error) {
        console.error('Error converting URL to file:', error)
        return null
    }
}

export const avatarName = (firstName = '', lastName = '') => {
    // return `https://ui-avatars.com/api/?background=random&rounded=true&name=${
    return `https://ui-avatars.com/api/?name=${firstName || ''}+${
        lastName || ''
    }`
}

export const convertCentsToDollars = (cents = 0) => {
    return `$${(cents / 100).toFixed(2)}`
}

export const parseBooleanVal = (val) => {
    if (val === 'yes' || val === 'true' || val === true) return true
    return false
}

export const capitalizeFirstLetter = (string) => {
    if (!string || string === undefined) return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalizeAllFirstLetters = (string) => {
    if (!string || string === undefined) return ''
    return string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
        letter.toUpperCase()
    )
}

export const isValidFile = (file, type) => {
    const fileName = file.name
    let exts = ''
    if (type === 'any') {
        exts = [
            'png',
            'jpg',
            'jpeg',
            'gif',
            'pdf',
            'doc',
            'docx',
            'xlsx',
            'csv',
            'xls',
        ]
    } else {
        exts = type === 'image' ? ['png', 'jpg', 'jpeg', 'gif'] : ['pdf']
    }

    if (fileName) {
        let getExt = fileName.split('.')
        getExt = getExt.reverse()
        if (!exts.includes(getExt[0].toLowerCase())) {
            if (type === 'any') {
                return 'only Pdf/Doc/Excel/Image files are allowed'
            }
            return type === 'image'
                ? 'only Image files are allowed'
                : 'only Pdf files are allowed'
        }

        // if (file.size / 1024 / 1024 > 10) {
        //   return "max. 10MB file size allow";
        // }

        return ''
    }
    return ''
}

export const containsWhitespace = (str) => {
    if (!str) return false
    const whitespaceRegex = /\s/
    return whitespaceRegex.test(str)
}

export const formatDateToMMMDDYYY = (date) => {
    if (!date) return ''
    return moment(date).format('MMM DD, YYYY')
}

export const transformData = (data, value) => {
    const transformed = {
        week: {
            series: [{ name: `${value}`, data: [] }],
            timeRange: [],
            total: 0,
        },
        month: {
            series: [{ name: `${value}`, data: [] }],
            timeRange: [],
            total: 0,
        },
        year: {
            series: [{ name: `${value}`, data: [] }],
            timeRange: [],
            total: 0,
        },
    }

    const now = dayjs()

    // Calculate the start of the week (Sunday)
    const startOfWeek = now.startOf('week')

    // Calculate the start of the month
    const startOfMonth = now.startOf('month')

    // Calculate the start of the year
    const startOfYear = now.startOf('year')

    // Initialize the arrays for week and month views
    for (let i = 0; i < 7; i++) {
        const day = startOfWeek.add(i, 'day')
        transformed.week.timeRange.push(day.format('YYYY-MM-DD'))
        transformed.week.series[0].data.push(0)
    }

    const daysInMonth = now.daysInMonth()
    for (let i = 1; i <= daysInMonth; i++) {
        const day = startOfMonth.add(i - 1, 'day')
        transformed.month.timeRange.push(day.format('YYYY-MM-DD'))
        transformed.month.series[0].data.push(0)
    }

    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    months.forEach((month) => {
        transformed.year.timeRange.push(month)
        transformed.year.series[0].data.push(0)
    })

    data.forEach((item) => {
        const createdDate = dayjs(item.created_at)
        const generationCount = item.result_images.length
        if (createdDate.isSame(startOfWeek, 'week')) {
            const day = createdDate.format('YYYY-MM-DD')
            const dayIndex = transformed.week.timeRange.indexOf(day)
            if (dayIndex !== -1) {
                transformed.week.series[0].data[dayIndex] += generationCount
                transformed.week.total += generationCount
            }
        }
        if (createdDate.isSame(startOfMonth, 'month')) {
            const day = createdDate.format('YYYY-MM-DD')
            const dayIndex = transformed.month.timeRange.indexOf(day)
            if (dayIndex !== -1) {
                transformed.month.series[0].data[dayIndex] += generationCount
                transformed.month.total += generationCount
            }
        }
        const month = createdDate.format('MMM')
        const monthIndex = transformed.year.timeRange.indexOf(month)
        if (monthIndex !== -1) {
            transformed.year.series[0].data[monthIndex] += generationCount
            transformed.year.total += generationCount
        }
    })

    return transformed
}

export const transformDataVisits = (data, value) => {
    const transformed = {
        week: {
            series: [{ name: `${value}`, data: [] }],
            timeRange: [],
            total: 0,
        },
        month: {
            series: [{ name: `${value}`, data: [] }],
            timeRange: [],
            total: 0,
        },
        year: {
            series: [{ name: `${value}`, data: [] }],
            timeRange: [],
            total: 0,
        },
    }

    const now = dayjs()

    // Calculate the start of the week (Sunday)
    const startOfWeek = now.startOf('week')

    // Calculate the start of the month
    const startOfMonth = now.startOf('month')

    // Calculate the start of the year
    const startOfYear = now.startOf('year')

    // Initialize the arrays for week and month views
    for (let i = 0; i < 7; i++) {
        const day = startOfWeek.add(i, 'day')
        transformed.week.timeRange.push(day.format('YYYY-MM-DD'))
        transformed.week.series[0].data.push(0)
    }

    const daysInMonth = now.daysInMonth()
    for (let i = 1; i <= daysInMonth; i++) {
        const day = startOfMonth.add(i - 1, 'day')
        transformed.month.timeRange.push(day.format('YYYY-MM-DD'))
        transformed.month.series[0].data.push(0)
    }

    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ]
    months.forEach((month) => {
        transformed.year.timeRange.push(month)
        transformed.year.series[0].data.push(0)
    })

    data.forEach((item) => {
        const createdDate = dayjs(item.created_at)
        const generationCount = item.visited
        if (createdDate.isSame(startOfWeek, 'week')) {
            const day = createdDate.format('YYYY-MM-DD')
            const dayIndex = transformed.week.timeRange.indexOf(day)
            if (dayIndex !== -1) {
                transformed.week.series[0].data[dayIndex] += generationCount
                transformed.week.total += generationCount
            }
        }
        if (createdDate.isSame(startOfMonth, 'month')) {
            const day = createdDate.format('YYYY-MM-DD')
            const dayIndex = transformed.month.timeRange.indexOf(day)
            if (dayIndex !== -1) {
                transformed.month.series[0].data[dayIndex] += generationCount
                transformed.month.total += generationCount
            }
        }
        const month = createdDate.format('MMM')
        const monthIndex = transformed.year.timeRange.indexOf(month)
        if (monthIndex !== -1) {
            transformed.year.series[0].data[monthIndex] += generationCount
            transformed.year.total += generationCount
        }
    })

    return transformed
}